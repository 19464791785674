import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Blog from '../../../components/blog/layout-two'
import Pagination from '../../../components/blog/pagination'
import { BlogWrapper, BlogBox } from './blog-area.style'

const BlogArea = ({ blogBoxStyle }) => {
    const BlogQuery = useStaticQuery(graphql`
        query ListImageBlogQuery {
            allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 6) {
                totalCount
                edges {
                    node {
                        frontmatter {
                            categories
                            title
                            author {
                                name
                                image {
                                    childImageSharp {
                                        fixed(width: 32, height: 32, quality: 100) {
                                            ...GatsbyImageSharpFixed_withWebp
                                        }
                                    }
                                }
                            }
                            format
                            quote_text
                            quote_author
                            video_link
                            date(formatString: "LL")
                            featured_image {
                                childImageSharp {
                                    fluid(maxWidth: 770, maxHeight: 420, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                        presentationHeight
                                        presentationWidth
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                            authorId
                            dateSlug
                        }
                        excerpt
                    }
                }
            }
             latestPosts : wpcontent {
                posts(where: {}, first: 10) {
                  edges {
                    node {
                      id      
                      postId
                      excerpt
                      featuredImage {
                        node {
                          id
                          mediaDetails {
                            file
                          }
                          sourceUrl
                          imageFile {
                            childImageSharp {
                              fluid(maxWidth: 1170, maxHeight: 570, quality: 100) {
                                presentationWidth
                                presentationHeight
                                ...GatsbyImageSharpFluid_withWebp
                              }
                            }
                          }
                        }
                      }
                      title(format: RENDERED)
                      author {
                        node {
                          id
                          name
                          avatar {
                            url
                          }
                        }
                      }
                      slug
                      date
                      tags(first: 10) {
                        edges {
                          node {
                            id
                          }
                        }
                      }
                      categories(first: 10) {
                        edges {
                          node {
                            id
                            name
                            slug
                            categoryId
                          }
                        }
                      }
                    }
                  }  
                  pageInfo {
                    endCursor
                    startCursor
                    hasNextPage
                    hasPreviousPage
                  }    
                }
              }
              postCount : wpcontent {
                posts(where: {}, first: 200) {
                  edges {
                    node {
                      id      
                    }    
                  }
                }
              }
        }      
    `)
    const blogs = BlogQuery.allMarkdownRemark.edges;
    const latestPosts = BlogQuery.latestPosts.posts.edges;
    const pageInfo = BlogQuery.latestPosts.posts.pageInfo;
    const postCount = BlogQuery.postCount.posts.edges.length;
    //const pageInfo = BlogQuery.latestPosts.posts.pageInfo;
    const postsPerPage = 10;
    const numberOfPages = Math.ceil(postCount / postsPerPage);
    return (
        <Fragment>
            <BlogWrapper>
                {latestPosts.map(blog => (
                    <BlogBox key={blog.node.slug}>
                        <Blog content={blog.node} />
                    </BlogBox>
                ))}
            </BlogWrapper>
            <Pagination
                rootPage="/blog"
                currentPage={1}
                numberOfPages={Math.round(postCount / postsPerPage)}
                pageInfo={pageInfo}
            />
        </Fragment>
    )
}


export default BlogArea;